import Link from "next/link"
import { URLS } from "../page-header/url"

export const LoginButton = () => {
    return <div className="flex flex-col gap-5  max-w-[300px]">
        <Link href={URLS.CV_TEMPLATES} className="p-5 rounded-md bg-[#1679dc] text-white shadow-2xl text-center font-medium flex gap-2 text-lg items-center justify-center">
            Get started - it&apos;s free
        </Link>
        <Link href="/register" className="hover:underline">
            You don&apos;t have an account? <span className="text-sky-500 underline">Register</span>
        </Link>
    </div>
}