import React, { useRef, useEffect } from 'react';
import { LoginButton } from '../login-components/login-link-button';

const HeaderSection: React.FC = () => {
    const animationContainer = useRef(null);

    useEffect(() => {
        if (typeof window !== 'undefined' && animationContainer.current) {
            const lottie = require('lottie-web');
            lottie.loadAnimation({
                container: animationContainer.current,
                renderer: 'svg',
                loop: true,
                autoplay: true,
                path: '/lottie.json',
            });
        }
    }, []);

    return (
        <section className="bg-blue-gradient pb-10 min-h-screen flex flex-col justify-between">
            <div className="container mx-auto min-h-screen px-6 grid grid-cols-1 md:grid-cols-2 gap-10 items-center">
                <div>
                    <h1 className="text-5xl  md:text-6xl font-bold text-gray-900 mb-4">
                        Build Resume & Cover Letter With AI-Powered Resume Builder
                    </h1>
                    <p className="text-xl md:text-2xl text-gray-600 mb-8">
                        Create, edit, collaborate your resume under one platform
                    </p>
                    <LoginButton />
                </div>
                <div className="flex justify-center items-center">
                    <div
                        ref={animationContainer}
                        className="w-full h-96" 
                        aria-hidden="true" // Use aria-hidden if the animation is decorative
                        role="img" // Use role="img" if the animation is informative, like a logo
                        aria-label="Animation description" // Add a label if it conveys meaningful info
                    />
                </div>
            </div>
            <p className="text-gray-400 text-center">
                Build resumes that stand out to both employers and Applicant Tracking Systems (ATS).
            </p>
        </section>
    );
};

export default HeaderSection;