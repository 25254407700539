import { FacebookFilled, LinkedinFilled } from "@ant-design/icons";
import { FacebookOutlined, LinkedinOutlined } from '@ant-design/icons';
import Link from "next/link";
import React from "react";
import { URLS } from "../page-header/url";

export default function PageFooter() {
  return (
    <div className="text-sm">
      <div className="p-2 m-auto grid grid-cols-1 md:grid-cols-2 justify-center align-middle items-center">
        <div className="flex items-center"><p className="m-0 p-0">{`@ ${new Date().getFullYear()}. All Rights Reserved by`} <span className="text-gray-500">Neuraforge.AI</span></p></div>
        <div>
          <div className="flex gap-5 items-end justify-end">
            <Link href="https://www.facebook.com/profile.php?id=61565340870069" target="blank"><span><FacebookFilled /></span></Link>
            <Link href="https://www.linkedin.com/in/neuraforge-ai-255152326" target="blank"><span><LinkedinFilled /></span></Link>
          </div>
        </div>
      </div>
    </div>
  );
}


export const Footer: React.FC = () => {
  return (
    <footer className="bg-white text-gray-700 py-5 mt-5">
      <div className="container mx-auto px-6">
        {/* Grid Container */}
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Platform Section */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Platform</h3>
            <ul>
              <li><Link href={URLS.PRICING} className="hover:text-blue-400">Pricing</Link></li>
              <li><Link href={URLS.BLOG} className="hover:text-blue-400">Career Blog</Link></li>
              <li><Link href={URLS.VIDEOS} className="hover:text-blue-400">Videos</Link></li>
            </ul>
          </div>

          {/* ATS Resume Section */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Blogs</h3>
            <ul>
              <li><Link href={URLS.HOW_TO_WRITE_COVER_LETTER} className="hover:text-blue-400">How To Write Cover Letter</Link></li>
              <li><Link href={URLS.HOW_TO_OPTMIZE_YOUR_RESUME} className="hover:text-blue-400">How To Optmize Resume</Link></li>
              <li><Link href={URLS.TOP_RESUME_MISTAKES} className="hover:text-blue-400"> Top Resume Mistakes</Link></li>
              <li><Link href={URLS.HOW_TO_WRITE_ATS_RESUME} className="hover:text-blue-400"> How To Write ATS resume</Link></li>
              <li><Link href={URLS.RESUME_TEMPLATE_CATEGORIES} className="hover:text-blue-400"> Resume Template categories</Link></li>
              <li><Link href={URLS.ATS_RESUME_SCORE} className="hover:text-blue-400">Resume Score</Link></li>
            </ul>
          </div>

          {/* Cover Letter Section */}

          <div>
            <h3 className="text-lg font-semibold mb-4">Cover Letter</h3>
            <ul>
              <li><Link href={URLS.CHATGPT_COVER_LETTER} className="hover:text-blue-400">GPT Cover Letter</Link></li>
              <li><Link href={URLS.RESUME_COVER_LETTER_DIFFERENCE} className="hover:text-blue-400">Resume Cover Letter Difference</Link></li>
              <li><Link href={URLS.COVER_LETTER_SAMPLES} className="hover:text-blue-400">Cover Letter Samples</Link></li>

            </ul>
          </div>

          {/* Support Section */}
          <div>
            <h3 className="text-lg font-semibold mb-4">Support</h3>
            <ul>
              <li><Link href={URLS.CUSTOMER_SUPPORT} className="hover:text-blue-400">Customer Support</Link></li>
              <li><Link href={URLS.PRIVACY_POLICY} className="hover:text-blue-400">Privacy</Link></li>
              <li><Link href={URLS.TERMS_AND_CONDITIONS} className="hover:text-blue-400">Terms</Link></li>
              <li><Link href={URLS.FAQ} className="hover:text-blue-400">FAQ</Link></li>
            </ul>
          </div>
        </div>

        {/* Social Media and Compliance */}
        <div className="flex flex-col md:flex-row justify-between items-center mt-8">
          <div className="flex space-x-6 mb-4 md:mb-0">
            <Link href="https://www.facebook.com/profile.php?id=61565340870069" target="_blank" rel="noopener noreferrer" className="hover:text-blue-400"><FacebookOutlined className="text-xl" /></Link>
            <Link href="https://www.linkedin.com/in/neuraforge-ai-255152326" target="_blank" rel="noopener noreferrer" className="hover:text-blue-400"><LinkedinOutlined className="text-xl" /></Link>
          </div>

          <div className="text-sm">
            {`© ${new Date().getFullYear()} ${process.env.NEXT_PUBLIC_APP_TITLE}`}
          </div>
        </div>
      </div>
    </footer>
  );
};
