// src/components/Header.tsx
import React, { useState } from "react";
import { MenuOutlined } from "@ant-design/icons";
import Logo from "./logo";
import DesktopMenu from "./desktop-menu";
import UserSessionControls from "./user-session-controls";
import MobileDrawer from "./mobile-drawer";

const Header: React.FC<any> = ({ isLoginMode = false }) => {
    const [drawerVisible, setDrawerVisible] = useState(false);

    // Function to toggle the drawer
    const showDrawer = () => setDrawerVisible(true);
    const closeDrawer = () => setDrawerVisible(false);

    return (
        <header>
            <div className="container-fluid md:container mx-auto">
                <nav className={`m-auto p-5 `}>
                    <div className="flex gap-10 justify-between items-center">

                        <div className="flex gap-5">
                            {/* Logo */}
                            <Logo />
                            {
                                !isLoginMode && <DesktopMenu />
                            }
                            
                        </div>


                        {
                            !isLoginMode && <div className="gap-5 flex justify-end items-center">

                                {/* User Session */}
                                <UserSessionControls />

                                {/* Mobile Menu Icon */}
                                <div className="lg:hidden flex justify-end">
                                    <MenuOutlined className="text-2xl cursor-pointer" onClick={showDrawer} />
                                </div>
                            </div>
                        }
                    </div>
                </nav>

                {/* Mobile Drawer */}
                <MobileDrawer drawerVisible={drawerVisible} closeDrawer={closeDrawer} />
            </div>
        </header>
    );
};

export default Header;