import dynamic from 'next/dynamic';
import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../redux/store';
import { endFreeTrail, toggleConsentDialog } from '../redux/app-slice';
import { Footer } from '../components/page-footer';
import Head from 'next/head';
import HeaderSection from '../components/page-components/home-page-header';
import Header from '../components/page-header/header';
import { motion, AnimatePresence } from 'framer-motion';

const TemplatesSection = dynamic(() => import('../components/page-components/home-template-section'), { ssr: true });
const HowItWorksSection = dynamic(() => import('../components/home-page-components/how-it-works'), { ssr: true });
const HeroSection = dynamic(() => import('../components/home-page-components/hero-section'), { ssr: true });
const ScoreSection = dynamic(() => import('../components/home-page-components/score-banner'), { ssr: true });
const WhyChoosUsSection = dynamic(() => import('../components/home-page-components/why-choose-us'), { ssr: true });
const CoverLetterSection = dynamic(() => import('../components/home-page-components/cover-letter-section'), { ssr: true });
const JobSearchToolWidget = dynamic(() => import('../components/home-page-components/jobsearch-tool-widget'), { ssr: true });
const PricingTable = dynamic(() => import('../components/home-page-components/pricing-table'), { ssr: true });

const Layout = ({ children }) => (
  <AnimatePresence>
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      {children}
    </motion.div>
  </AnimatePresence>
);


const App: React.FC = () => {
  const dispatch = useAppDispatch();

  const userUpdated = useAppSelector((state) => state.app.marketingCookie?.userUpdated);

  useEffect(() => {

    if (!userUpdated) {
      dispatch(toggleConsentDialog(true));
    }

  }, [userUpdated])

  useEffect(() => {
    dispatch(endFreeTrail());
  }, [dispatch]);

  return (
    <div className="min-h-screen">

      <Head>
      <title>AI-Powered Resume Builder | Create Professional Resumes & Cover Letters</title>
        <meta name="description" content="Create a professional resume in minutes with our AI-powered resume builder. Choose from ATS-friendly templates, generate personalized cover letters, and optimize your resume for free." />
        <meta name="keywords" content="resume builder, free resume templates, premium resume templates, ATS resume, AI-powered resume builder, cover letter generator, resume scoring, professional resume, job search, resume optimization" />
        <meta name="author" content={process.env.NEXT_PUBLIC_APP_TITLE} />
        <meta property="og:title" content="AI-Powered Resume Builder | NeuraCV" />
        <meta property="og:description" content="Create professional resumes with our AI-powered resume builder." />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.neuracv.com" />
        <meta property="og:image" content="/og-image.png" />
        <link rel="canonical" href={`${process.env.NEXT_PUBLIC_APP_DOMAIN_URL}`} />
      </Head>

      <Header />

      <Layout>

        <HeaderSection />
        <HowItWorksSection />
        <HeroSection />
        <ScoreSection />
        <WhyChoosUsSection />
        <CoverLetterSection />
        <JobSearchToolWidget />
        <TemplatesSection />
        <PricingTable />
        <Footer />

      </Layout>



    </div>
  );
};

export default App;